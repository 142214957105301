import { Button, GridContainer, GridItem, PageMeta } from "@eleo/components";
import { CustomNextPage } from "../../types/shared.types";
import {
  FeatureCardContainer,
  FeatureCardDescription,
  FeatureCardIcon,
  FeatureCardIconContainer,
  FeatureCardTitle,
  FeatureHeaderContainer,
  FooterLink,
  FooterLinks,
  LandingContainer,
  LandingContainerInner,
  LandingHeadline,
  LandingImg,
  Logo,
  LogoContainer,
  Nav,
  NavCTAContainer,
  NavLink,
  NavLinks,
  NavWrapper,
  SectionCaption,
  SectionHeader,
  SectionInnerContainer,
  Wrapper,
} from "./LandingPage.styles";
import Link from "next/link";
import React, { useEffect } from "react";
import { useUserData } from "../../contexts/auth.context";
import { injectStoreIntoLink } from "@eleo/suite/services/router";

const LandingCTA = () => {
  const { user } = useUserData();

  const isLoggedIn = Boolean(user);

  return (
    <Link href={isLoggedIn ? injectStoreIntoLink("/dashboard") : "/auth/login"}>
      <Button type="button" size="large">
        {isLoggedIn ? "Go to Dashboard" : "Get Started"}
      </Button>
    </Link>
  );
};

export type FeatureItem = {
  title: string;
  description: string;
  icon: string;
  bgColor: string;
};

export type FeatureCardProps = {
  feature: FeatureItem;
};

const features: FeatureItem[] = [
  {
    title: "One-stop Retail Solution",
    description:
      "Eleo combines all essential functionalities needed to streamline any retail business. Keep everything at your fingertips.",
    icon: "🛍️",
    bgColor: "rgba(181, 228, 202, 0.2)",
  },
  {
    title: "Inventory Management",
    description:
      "Effortlessly track your stock, ensuring you never run out of products or overstock items.",
    icon: "📦",
    bgColor: "rgba(255, 188, 153, 0.2)",
  },
  {
    title: "Comprehensive Bookkeeping",
    description:
      "Maintain financial clarity with our integrated bookkeeping system. Monitor cash flow, revenue, and expenses all in one place.",
    icon: "💹",
    bgColor: "rgba(204, 181, 228, 0.2)",
  },
  {
    title: "Centralized Customer Database",
    description:
      "Manage customer details and purchase history with ease, enhancing your customer relations.",
    icon: "👤",
    bgColor: "rgba(228, 228, 181, 0.2)",
  },
  {
    title: "Unified Supplier Database",
    description:
      "Keep track of supplier information and products, ensuring smooth supply chain operations.",
    icon: "🤝",
    bgColor: "rgba(181, 202, 228, 0.2)",
  },
  {
    title: "Efficient Payment System",
    description:
      "Securely process payments, refunds, and track transactions with ease.",
    icon: "💳",
    bgColor: "rgba(255, 188, 153, 0.2)",
  },
  {
    title: "Interactive Dashboard",
    description:
      "Use our intuitive dashboard to get a comprehensive overview of" +
      " your store's performance and operations.",
    icon: "📊",
    bgColor: "rgba(181, 228, 202, 0.2)",
  },
  {
    title: "Dynamic e-Commerce Platform",
    description:
      "Allow customers to browse, search, and self-checkout with our user-friendly e-commerce platform.",
    icon: "🌐",
    bgColor: "rgba(228, 204, 181, 0.2)",
  },
  {
    title: "Business Growth & Scalability",
    description:
      "Eleo is built for growth. As your business expands, our platform scales with you, ensuring consistent performance and efficiency.",
    icon: "📈",
    bgColor: "rgba(204, 228, 202, 0.2)",
  },
];

const FeatureCard = ({ feature }: FeatureCardProps) => {
  return (
    <FeatureCardContainer>
      <FeatureHeaderContainer>
        <FeatureCardIconContainer
          css={{
            backgroundColor: feature.bgColor,
          }}
        >
          <FeatureCardIcon>{feature.icon}</FeatureCardIcon>
        </FeatureCardIconContainer>

        <FeatureCardTitle type="h3">{feature.title}</FeatureCardTitle>
      </FeatureHeaderContainer>

      <FeatureCardDescription>{feature.description}</FeatureCardDescription>
    </FeatureCardContainer>
  );
};

const LandingPage: CustomNextPage = () => {
  const [scrolled, setScrolled] = React.useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled((prevScrolled) => {
        if (isScrolled !== prevScrolled) {
          return !prevScrolled;
        }
        return prevScrolled;
      });
    };

    document.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <PageMeta
        description="Eleo is a one-stop retail management and e-commerce platform that helps you streamline your retail operations, delight customers, and grow your business."
        title="Eleo: One-Stop Retail Management & E-commerce Platform"
        skipSuffix
      />

      <Nav scrolled={scrolled}>
        <NavWrapper>
          <Link href={"/"}>
            <LogoContainer>
              <Logo width={140} height={40} src="/images/logo.svg" alt="Eleo" />
            </LogoContainer>
          </Link>

          <NavLinks>
            <NavLink>
              <a href="#features">Features</a>
            </NavLink>

            {/*<NavLink>*/}
            {/*  <a href="#pricing">Pricing</a>*/}
            {/*</NavLink>*/}

            {/*<NavLink>*/}
            {/*  <a href="#faq">FAQ</a>*/}
            {/*</NavLink>*/}
          </NavLinks>

          <NavCTAContainer>
            <LandingCTA />
          </NavCTAContainer>
        </NavWrapper>
      </Nav>

      <Wrapper>
        <LandingContainer>
          <LandingContainerInner>
            <GridContainer width={800} centered>
              <GridItem>
                <LandingHeadline type="h1">
                  One Platform,
                  <br />
                  Endless Capabilities.
                </LandingHeadline>
              </GridItem>

              <GridItem>
                <SectionCaption>
                  Transform your retail journey, delight customers, and
                  streamline operations with Eleo.
                </SectionCaption>
              </GridItem>

              <GridItem
                centered
                css={{
                  margin: "$3 auto",

                  "@bp2a": {
                    marginBottom: "120px",
                  },
                }}
              >
                <LandingCTA />
              </GridItem>

              <GridItem>
                <LandingImg
                  src={"https://media.eleo.app/dashboard.png"}
                  alt={"Dashboard"}
                />
              </GridItem>
            </GridContainer>
          </LandingContainerInner>
        </LandingContainer>

        <SectionInnerContainer id="features">
          <GridContainer>
            <GridItem centered>
              <SectionHeader
                type="h2"
                css={{
                  marginBottom: "$1",
                }}
              >
                Features
              </SectionHeader>
            </GridItem>

            <GridItem centered>
              <SectionCaption
                css={{
                  marginBottom: "$2",
                }}
              >
                Unveiling a suite of tools to elevate and streamline your retail
                operations.
              </SectionCaption>
            </GridItem>

            {features.map((feature) => (
              <GridItem tablet={6} desktop={4} key={feature.title}>
                <FeatureCard feature={feature} />
              </GridItem>
            ))}
          </GridContainer>
        </SectionInnerContainer>

        <GridItem
          as="footer"
          css={{
            backgroundColor: "$darkSlate",
            marginBottom: "-24px",
          }}
        >
          <SectionInnerContainer>
            <GridContainer>
              <GridItem>
                <FooterLinks>
                  <FooterLink>
                    <a href="https://instagram.com/Foodster.ng">Instagram</a>
                  </FooterLink>

                  <FooterLink>
                    <a href="https://twitter.com/FoodsterNG">Twitter</a>
                  </FooterLink>

                  <FooterLink>
                    <a href="https://eleo.app/discover">Marketplace</a>
                  </FooterLink>
                </FooterLinks>
              </GridItem>

              <GridItem centered>
                <span>
                  &copy; {new Date().getFullYear()} Eleo. All rights reserved.
                </span>
              </GridItem>
            </GridContainer>
          </SectionInnerContainer>
        </GridItem>
      </Wrapper>
    </>
  );
};

LandingPage.layoutOptions = {
  hideSideMenu: true,
  hideTopNav: true,
  hideFloatingActions: true,
  mainContainerStyles: {
    padding: "0",
  },
};

export default LandingPage;
